import { createSlice } from '@reduxjs/toolkit'
import { IFiles } from '../../models/Files'

interface IConversationState {
  newConversation: {
    message: string
    file: File | null
    fileName: string
    attachments?: IFiles[]
    tempAttachments?: IFiles[]
    tempFile?: File | null
    tempFileName?: string
    conversationId?: string | null
  }
  holdFilesForBeforeShow?: IFiles[]
}

const initialState: IConversationState = {
  newConversation: {
    message: '',
    file: null,
    fileName: '',
    attachments: [],
    tempAttachments: [],
    tempFile: null,
    tempFileName: '',
    conversationId: null
  },
  holdFilesForBeforeShow: []
}

export const isAttachment = (attachments: IFiles[], selectedFile: IFiles) => {
  return attachments.findIndex((f: IFiles) => selectedFile.FILE_ID == f.FILE_ID) !== -1
}

export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setFileData: (state, action) => {
      state.newConversation = action.payload
      state.holdFilesForBeforeShow = action.payload.attachments || []
    },
    clearFileData: (state) => {
      state.newConversation.file = null
      state.newConversation.fileName = ''
      state.newConversation.message = ''
      state.newConversation.attachments = []
      state.newConversation.tempAttachments = []
      state.newConversation.tempFile = null
      state.newConversation.tempFileName = ''
      state.newConversation.conversationId = ''
    },

    addAndRemoveAttachment: (state, action) => {
      const arr = state.newConversation.tempAttachments!
      const files = isAttachment(arr, action.payload)
        ? arr.filter((item) => item.FILE_ID !== action.payload.FILE_ID)
        : [...arr, action.payload]

      state.newConversation.tempAttachments = files
    },
    removeSingleAttachment: (state, action) => {
      state.newConversation.attachments = state.newConversation.tempAttachments?.filter(
        (attachment) => attachment.FILE_ID !== action.payload.FILE_ID
      )
      state.newConversation.tempAttachments = state.newConversation.attachments
      state.holdFilesForBeforeShow = state.newConversation.attachments
    },
    removeFile: (state) => {
      state.newConversation.file = null
      state.newConversation.fileName = ''
      state.newConversation.tempFile = null
      state.newConversation.tempFileName = ''
    },
    addFileInTemp: (state, action) => {
      state.newConversation.attachments = action.payload
    },
    setHoldFilesForBeforeShow: (state, action) => {
      state.holdFilesForBeforeShow = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setFileData,
  clearFileData,
  addAndRemoveAttachment,
  removeSingleAttachment,
  removeFile,
  setHoldFilesForBeforeShow
} = conversationSlice.actions

export const selectFile = (state: { conversation: IConversationState }) =>
  state.conversation.newConversation

export const selectHoldFilesForBeforeShow = (state: { conversation: IConversationState }) =>
  state.conversation.holdFilesForBeforeShow

export default conversationSlice.reducer
