import { jwtDecode, JwtPayload } from 'jwt-decode'
import { ILoginRequestDTO } from '../models/User'
import api from './api.service'
import { loginRequest, msalInstance } from '../utills/azureAuthConfig'

export class AuthService {
  login(user: ILoginRequestDTO) {
    const params = new URLSearchParams(user as unknown as Record<string, string>)
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    return api.post('guppy/api/v0/token', params, config)
  }

  saveToken(token: string) {
    localStorage && localStorage.setItem('accessToken', token)
  }

  getToken() {
    return localStorage.getItem('accessToken')
  }

  isUserLoggedIn() {
    return !!localStorage.getItem('accessToken')
  }

  getUserDetails() {
    return api.get('guppy/api/v0/users/me/get-details')
  }

  isExpired() {
    const token = localStorage.getItem('accessToken')
    if (token) {
      const userData = jwtDecode<JwtPayload>(token)
      // Set the token before 60 seconds of expiry
      const timestamp = new Date()
      timestamp.setSeconds(timestamp.getSeconds() + 60) //

      if (userData) return Date.now() >= userData.exp! * 1000
    }
    return true
  }

  logout() {
    localStorage.removeItem('accessToken')
  }

  async setSSOSilentToken() {
    const tokenResponse = await msalInstance.ssoSilent(loginRequest)
    localStorage.setItem('accessToken', tokenResponse.idToken)
  }
}
const authService = new AuthService()

export default authService
