import React, { Component, ErrorInfo } from 'react'
import { IconSmallLogo } from '../../components/icons'

interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundaryPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error: ', error, errorInfo)
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 15,
            flexDirection: 'column'
          }}
        >
          <IconSmallLogo width={130} height={130} />
          <h1>Something went wrong. Please try to reload again...</h1>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundaryPage
